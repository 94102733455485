var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Info = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M11.069 1.039c-2.247.219-4.201 1.002-5.968 2.393-.461.363-1.495 1.431-1.87 1.931C2.075 6.906 1.379 8.59 1.093 10.54c-.098.672-.098 2.248 0 2.92.286 1.949.981 3.631 2.137 5.177.455.607 1.526 1.678 2.133 2.133 1.546 1.156 3.228 1.851 5.177 2.137.672.098 2.248.098 2.92 0 1.949-.286 3.631-.981 5.177-2.137.607-.455 1.678-1.526 2.133-2.133 1.156-1.546 1.851-3.228 2.137-5.177.052-.356.07-.733.07-1.46s-.018-1.104-.07-1.46c-.286-1.95-.982-3.634-2.138-5.177-.375-.5-1.409-1.568-1.87-1.931-1.597-1.257-3.351-2.02-5.319-2.312-.634-.094-1.942-.136-2.511-.081m1.358 6.054c.744.385.766 1.42.038 1.794C11.814 9.222 11 8.729 11 8c0-.385.288-.803.643-.934.214-.078.606-.065.784.027m-.087 3.973c.253.087.507.341.594.594.058.171.066.448.066 2.34 0 2.427.003 2.399-.299 2.701a.985.985 0 0 1-1.402 0C10.997 16.399 11 16.427 11 14s-.003-2.399.3-2.702a.98.98 0 0 1 1.04-.232" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Info;
